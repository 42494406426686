<ion-content class="has-header" overflow-scroll="true">
  <ion-row style="position: relative; min-height: 100%">
    <ion-col
      style="margin-bottom: 80px"
      size="12"
      size-lg="10"
      offset-lg="1"
      size-xl="10"
      offset-xl="1"
    >
      <app-header title="userStats" backTo="start" show="true"></app-header>
      <ion-progress-bar
        *ngIf="statsLoading"
        type="indeterminate"
      ></ion-progress-bar>
      <div *ngIf="!statsLoading" class="container">
        <h1>{{ "obsByRecord" | translate | capitalizeFirstLetter }}</h1>
        <app-record-count-graph [data]="stats.recordCount"></app-record-count-graph>
        <h1>{{ "recordsByYear" | translate | capitalizeFirstLetter }}</h1>
        <app-record-by-year-chart [data]="stats.recordByYearCount"></app-record-by-year-chart>
        <h1>{{ "totalObsByDate" | translate | capitalizeFirstLetter }}</h1>
        <app-observations-by-date-chart [data]="stats.observationsByDateCount"></app-observations-by-date-chart>
      </div>
    </ion-col>
    <app-footer></app-footer>
  </ion-row>
</ion-content>
