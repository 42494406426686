import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatsComponent } from './stats.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StatsRoutingModule } from './stats-routing.module';
import { RecordByYearChartComponent } from './record-by-year-chart/record-by-year-chart.component';
import { RecordCountGraphComponent } from './record-count-graph/record-count-graph.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ObservationByDateChartComponent } from './observations-by-year-chart/observations-by-date-chart.component';
@NgModule({
  declarations: [
    StatsComponent,
    RecordByYearChartComponent,
    RecordCountGraphComponent,
    ObservationByDateChartComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StatsRoutingModule,

    HighchartsChartModule,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StatsModule {}
