import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-record-count-graph',
  templateUrl: './record-count-graph.component.html',
  styleUrls: ['./record-count-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordCountGraphComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts; // required

  chartOptions: Highcharts.Options;

  @Input() data: { recordNumber: number, count: number }[]


  constructor(private _translate: TranslateService) { }


  ngOnInit(): void {

    const data = this.data.map(elem => ([elem.recordNumber,elem.count]))

    this.chartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text:""
      },
      xAxis: {
        title: {
          text: this._translate.instant("recordNumber"),
        },
        labels: {
          enabled: true,
          formatter: function() { return data[this.value].name},
        }
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: {
          text: this._translate.instant("total"),
          align: 'middle',
        },
        labels: {
          overflow: 'justify',
        },
        stackLabels: {
          enabled: true,
        },
      },
      legend: { enabled: false},
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat:"",
        pointFormat: '<b>'+this._translate.instant("recordNumber") + '{point.name}:</b><br/> {point.y} observation(s)',
      },
      series: [
        {
          name: "",
          type: 'column',
          data: data,
          color: '#FD3F92',
        }
      ],
      credits: {
        enabled: false,
      },
    };

  }
}
