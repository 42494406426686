import {
  ChangeDetectionStrategy, Component,

  Input,


  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-observations-by-date-chart',
  templateUrl: './observations-by-date-chart.component.html',
  styleUrls: ['./observations-by-date-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ObservationByDateChartComponent implements OnInit {

  @Input() data: {date: string, count: number}[];

  Highcharts: typeof Highcharts = Highcharts; // required

  chartRef: Highcharts.Chart;

  chartOptions: Highcharts.Options;

  constructor(private _translate: TranslateService,
    public dialog: MatDialog) { }
  ngOnInit(): void {
    const formattedData = []
    this.data.reduce((accu, currentvalue, index)=>{
      formattedData[index] = {x: Date.parse(currentvalue.date), y:currentvalue.count + accu};
      return formattedData[index].y;
    },0)
    
    this.chartOptions = {
      title: {
        text: ''
      },
      chart: {
        type: "spline",
      },
      xAxis: {
        labels: {
          format: "{value:%e-%m-%y}"
        },
        type: "datetime",
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: this._translate.instant("observations"),
          align: 'middle',
        },
      },
      legend: {enabled: false},
      tooltip: {
        headerFormat: '<b>{point.x:%e-%m-%y}</b><br/>',
        pointFormat: '{point.y} observation(s)',
        valueDecimals: 0,
        split: true,
      },
      series: [
        {
          name: this._translate.instant('assimilated'),
          data: formattedData,
          type: 'spline',
          color: '#FD3F92',
        }
      ],
      time: {
        timezone: "Europe/Zurich",
        useUTC: false
      }
    };
  }
}
